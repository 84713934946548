function setCameraBinding(element) {
  document.getElementById(element).setAttribute('set_bind', 'true');
}

function resetViewpoint(thisButton, element) {
  document.getElementById('x3d').runtime.resetView();
  var orthoViewpoints = document.getElementById("x3d").getElementsByTagName("OrthoViewpoint");
  for (var i=0; i<orthoViewpoints.length; i++){
    document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('fieldOfView', [-7, -7, 7, 7]);
  }
}

function resetViewpointUK(x) {
  document.getElementById('x3d').runtime.resetView();
  var orthoViewpoints = document.getElementById("x3d").getElementsByTagName("OrthoViewpoint");
  for (var i=0; i<orthoViewpoints.length; i++){
    if (document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].id === 'viewpointOben'){
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('position', {x: 0, y: x, z: 0});
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('fieldOfView', [(-x*0.75), (-x*0.75), (x*0.75), (x*0.75)]);

    }else if(document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].id === 'viewpointLinks'){
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('position', {x: -x, y: 0, z: 0});
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('fieldOfView', [(-x*0.75), (-x*0.75), (x*0.75), (x*0.75)]);

    }else if(document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].id === 'viewpointFront'){
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('position', {x: 0, y: 0, z: x});
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('fieldOfView', [(-x*0.75), (-x*0.75), (x*0.75), (x*0.75)]);
    }else{
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('position', {x: -10, y: 5, z: 10});
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('fieldOfView', [(-x*0.75), (-x*0.75), (x*0.75), (x*0.75)]);
    }
  }
}

function resetViewpointDK(x) {
  document.getElementById('x3d').runtime.resetView();
  var orthoViewpoints = document.getElementById("x3d").getElementsByTagName("OrthoViewpoint");
  for (var i=0; i<orthoViewpoints.length; i++){
    if (document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].id === 'viewpointOben'){
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('position', {x: 0, y: x, z: 0});
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('fieldOfView', [(-x*0.5), (-x*0.5), (x*0.5), (x*0.5)]);

    }else if(document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].id === 'viewpointLinks'){
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('position', {x: -x, y: 1, z: 0});
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('fieldOfView', [(-x*0.5), (-x*0.5), (x*0.5), (x*0.5)]);

    }else if(document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].id === 'viewpointFront'){
        document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('position', {x: 0, y: 1, z: x});
        document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('fieldOfView', [(-x*0.5), (-x*0.5), (x*0.5), (x*0.5)]);

    }else{
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('position', {x: -x, y: 0.5*x, z: x});
      document.getElementById("x3d").getElementsByTagName("OrthoViewpoint")[i].setFieldValue('fieldOfView', [(-x*0.65), (-x*0.65), (x*0.65), (x*0.65)]);
    }
  }
}
